/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    h3: "h3",
    code: "code",
    hr: "hr"
  }, _provideComponents(), props.components), {Type, Params} = _components;
  if (!Params) _missingMdxReference("Params", true);
  if (!Type) _missingMdxReference("Type", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "overview",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#overview",
    "aria-label": "overview permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "Overview"), "\n", React.createElement(_components.p, null, "The Move component moves the carousel by applying styles to the list element."), "\n", React.createElement(_components.h2, {
    id: "methods",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#methods",
    "aria-label": "methods permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "Methods"), "\n", React.createElement(_components.h3, {
    id: "move",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#move",
    "aria-label": "move permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "move()")), "\n", React.createElement(Type, {
    code: 'move( dest: number, index: number, prev: number ): void'
  }), "\n", React.createElement(_components.p, null, "Moves the carousel to the dest index with the Transition component.\nThis method does not update the current index. Use ", React.createElement(_components.code, null, "Controller#go"), " instead."), "\n", React.createElement(Params, {
    params: [['dest', 'A destination index to go to, including clones\'.'], ['index', 'A slide index.'], ['prev', 'A previous index.']]
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "jump",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#jump",
    "aria-label": "jump permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "jump()")), "\n", React.createElement(Type, {
    code: 'jump( index: number ): void'
  }), "\n", React.createElement(_components.p, null, "Jumps to the slide at the specified index."), "\n", React.createElement(Params, {
    params: [['index', 'An index to jump to.']]
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "translate",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#translate",
    "aria-label": "translate permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "translate()")), "\n", React.createElement(Type, {
    code: 'translate( position: number ): void'
  }), "\n", React.createElement(_components.p, null, "Moves the carousel to the provided position."), "\n", React.createElement(Params, {
    params: [['position', 'The position to move to.']]
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "cancel",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cancel",
    "aria-label": "cancel permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "cancel()")), "\n", React.createElement(Type, {
    code: 'cancel(): void'
  }), "\n", React.createElement(_components.p, null, "Cancels transition."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "toindex",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#toindex",
    "aria-label": "toindex permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "toIndex()")), "\n", React.createElement(Type, {
    code: 'toIndex( position: number ): number'
  }), "\n", React.createElement(_components.p, null, "Converts the provided position to the closest slide index."), "\n", React.createElement(Params, {
    params: [['position', 'A position to convert.']],
    ret: 'The closest index to the position.'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "toposition",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#toposition",
    "aria-label": "toposition permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "toPosition()")), "\n", React.createElement(Type, {
    code: 'toPosition( index: number, trimming?: boolean ): number'
  }), "\n", React.createElement(_components.p, null, "Converts the provided index to the position in pixel."), "\n", React.createElement(Params, {
    params: [['index', 'An index to convert.'], ['trimming', 'Optional. Whether to trim edge spaces or not.']],
    ret: 'The position corresponding with the index.'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "getposition",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#getposition",
    "aria-label": "getposition permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "getPosition()")), "\n", React.createElement(Type, {
    code: 'getPosition(): number'
  }), "\n", React.createElement(_components.p, null, "Returns the current position."), "\n", React.createElement(Params, {
    ret: 'The position of the list element.'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "getlimit",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#getlimit",
    "aria-label": "getlimit permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "getLimit()")), "\n", React.createElement(Type, {
    code: 'getLimit( max: boolean ): number'
  }), "\n", React.createElement(_components.p, null, "Returns the limit position that the carousel can move to."), "\n", React.createElement(Params, {
    params: [['max', 'Determines whether to return the maximum or minimum limit.']],
    ret: 'The border position.'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "exceededlimit",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#exceededlimit",
    "aria-label": "exceededlimit permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "exceededLimit()")), "\n", React.createElement(Type, {
    code: 'exceededLimit( max?: boolean | undefined, position?: number ): boolean'
  }), "\n", React.createElement(_components.p, null, "Checks if the provided position exceeds the minimum or maximum limit or not."), "\n", React.createElement(Params, {
    params: [['max', 'Optional. `true` for testing max, `false` for min, and `undefined` for both.'], ['position', 'Optional. A position to test. If omitted, tests the current position.']],
    ret: '`true` if the position exceeds the limit, or otherwise `false`.'
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
